/*body {*/
/*  position: relative;*/
/*  color: white;*/
/*  height: 100vh;*/
/*  background: linear-gradient(278.96deg, rgba(216, 219, 82, 0.4) 10.29%, rgba(208, 0, 237, 0.4) 36.56%, rgba(91, 142, 125, 0.4) 67.3%);*/
/*  !*font-family: sans-serif;*!*/
/*  overflow-y: hidden;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*}*/

body {
  justify-content: center;
}

.main {
  padding: 50px 150px 20px 150px;
  color: white;
  width: auto;
  height: auto;
}

.mainTo {
  padding: 50px 150px 0 150px;
  color: white;
  width: auto;
  height: 100vh;
  min-height: 800px;
  background: linear-gradient(278.96deg, rgba(216, 219, 82, 0.4) 10.29%, rgba(208, 0, 237, 0.4) 36.56%, rgba(91, 142, 125, 0.4) 67.3%);
}

.content {
  display: flex;
  justify-content: center;
}

.warning {
  text-align: center;
  color: red;
  margin-top: 15px;
  font-weight: bold;
}

.introTitle {
  /*font-family: DM Sans;*/
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  color: #FFFFFF;
  white-space: nowrap;
}

.input {
  display: flex;
  flex-direction: row;
  position: relative;
}

.urlInput {
  width: 877px;
  outline: none;
  height: 39px;
  border-radius: 10px;
  border: none;
  padding: 10px;
  font-size: 20px;
  color: #37373C;
}

.funcBtn {
  position: absolute;
  border-radius: 5px;
  right: 8px;
  z-index: 2;
  border: none;
  top: 5px;
  cursor: pointer;
  color: white;

  transform: translateX(2px);
  padding: 10px 22px 10px 22px;
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
}

.funcBtn:enabled {
  background: linear-gradient(105.75deg, #631AFF 6.78%, #631AFF 6.8%, #ED00E4 222.04%);
}

.funcBtn:disabled {
  background-color: #2D2F36;
  cursor: not-allowed;
}

.funcBtn:enabled:before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(15px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  opacity: 0;
  border-radius: 8px;
  background-color: #631AFF;
}

.funcBtn:enabled:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 8px;
  background: linear-gradient(105.75deg, #631AFF 6.78%, #631AFF 6.8%, #ED00E4 222.04%);
}

.funcBtn:enabled:hover:before {
  opacity: 1;
}

.endTitle, .comment {
  font-size: 20px;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.comment {
  margin-top: 20px;
}

.footerElement{
  font-size: 45px;
  line-height: 55px;
  color: #FFFFFF;
  font-weight: bold;
}

.header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 132px;
  padding-bottom: 127px;
}

.logo{

}

.menu{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.credential{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.item{
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  padding-left: 20px;
  position: relative;
  bottom: 3px;
}

.tryBtn{
  border-radius: 5px;
  border: none;
  cursor: pointer;
  color: white;
  background: linear-gradient(105.75deg, #631AFF 6.78%, #631AFF 6.8%, #ED00E4 222.04%);
  padding: 10px 22px 10px 22px;
  font-size: 24px;
  font-weight: bold;
  margin-top: -10px;
}

.videoOutline{
  display: flex;
  width: 938px;
  height: 200px;
  background: #2D2F36;
  border-radius: 10px;
}

.rItem{
  width: 230px;
  height: 60px;
}

.rFlag {
  width: 230px;
  height: 60px;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: lightgrey;
  padding-top: 10px;
}

.rFlag img {
  position: relative;
  top: 3px;
  left: 10px;
}

.rOption{
  width: 920px;
  font-weight: bold;
  font-size: 38px;
  line-height: 53px;
}

.txtBtn{
  width: 160px;
  height: 50px;
  border-radius: 8px;
  padding: 8px 20px 8px 20px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  cursor: pointer;
  color: white;
  background: none;
  font-size: 24px;
  font-weight: bold;
}

.txtBtn:hover {
  background-color: #2D2F36;
}

.editBtn {
  width: 160px;
  height: 50px;
  border-radius: 8px;
  padding: 8px 20px 8px 20px;
  cursor: pointer;
  color: white;
  border: none;
  background: linear-gradient(105.75deg, #631AFF 6.78%, #631AFF 6.8%, #ED00E4 222.04%);
  font-size: 24px;
  font-weight: bold;
  position: relative;
  z-index: 0;
}

.editBtn:before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(15px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  opacity: 0;
  border-radius: 8px;
  background-color: #631AFF;
}

.editBtn:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 8px;
  background: linear-gradient(105.75deg, #631AFF 6.78%, #631AFF 6.8%, #ED00E4 222.04%);
}

.editBtn:hover:before {
  opacity: 1;
}

.editBtn_auto {
  display: block;
  margin: 20px auto;
  margin-bottom: 0;
  width: auto;
}

.videoThumbnail {
  margin: auto;
  width: 238px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.videoTitle {
  margin: auto;
  width: 700px;
  display: flex;
  text-align: left;
  font-size: 22px;
}

.custom {
  width: 1101px;
  height: 268px;
  border: 30px solid;
  border-radius: 50px;
  border-image-slice: 1;
  border-image-source: linear-gradient(104.06deg, #FFF96B 11.53%, rgba(41, 92, 108, 0.949939) 49.86%, rgba(199, 113, 255, 0.914956) 64.54%, rgba(240, 62, 255, 0.88) 79.21%);
  /*background: linear-gradient(104.06deg, #FFF96B 11.53%, rgba(41, 92, 108, 0.949939) 49.86%, rgba(199, 113, 255, 0.914956) 64.54%, rgba(240, 62, 255, 0.88) 79.21%);*/
  filter: blur(15px);
  z-index: 0;
}

.custom1 {
  width: 1101px;
  color: white;
  font-size: 38px;
  text-align: center;
  position: absolute;
  top: 100px;
}

.extension_link {
  color: #fff;
}

.discover_our_subtitling_solution {
  color: #fff;
  text-decoration: none;
}

.discover_our_subtitling_solution:hover {
  text-decoration: underline;
}
